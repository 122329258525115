import './CustomerAgreement.css';

import { SendSharp, Warning } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';

import Spinner from '../../components/Spinner';
import {
  useGetApplicationInfoByInvoiceIdQuery,
  useSendTnCeSignatureMutation
} from '../../generated/graphql';
import getEnv from '../../utils/getEnv';
import { logUserActivity } from '../../utils/questionTracking';

const { REACT_APP_ADMIN_FRONTEND_URL } = getEnv();

const DEFAULT_EMAIL_SUBJECT =
  'ACTION REQUIRED: Reminder: Please sign application for purchase of Foxquilt business insurance';

interface CustomerAgreementProps {
  invoiceId: string;
}

const SIGN_OPTION_RADIO = 'ClientAgreeement_Complete_SelectSignOption';
const AGENT_AGREEMENT_CHECKBOX = 'ClientAgreeement_Complete_AgentAgreement';
const TNC_EMAIL_EDIT_SUBJECT_INPUT =
  'ClientAgreeement_Complete_TnCEmail_Subject_Edit';
const TNC_EMAIL_RESET_SUBJECT_INPUT =
  'ClientAgreeement_Complete_TnCEmail_Subject_Reset';
const TNC_SEND_ESIGN_BUTTON = 'ClientAgreeement_Complete_SendTnCESign';
const RETURN_TO_FOXDEN_BUTTON = 'ClientAgreeement_Complete_ReturnToFoxden';
const REDIRECT_TO_ESIGN_BUTTON = 'ClientAgreeement_Complete_RedirectToESign';

const CustomerAgreement: React.FC<CustomerAgreementProps> = ({ invoiceId }) => {
  const {
    data: getApplicationInfoDataResult,
    loading: getApplicationInfoLoading
  } = useGetApplicationInfoByInvoiceIdQuery({
    variables: {
      invoiceId
    }
  });

  const quoteId =
    getApplicationInfoDataResult?.getApplicationInfoByInvoiceId.quoteId;

  const customerName =
    getApplicationInfoDataResult?.getApplicationInfoByInvoiceId.customerName;
  // Gets customerFirstName from the first word in provided customerName, using 'there' if name does not exist
  const customerFirstName = customerName ? customerName.split(' ')[0] : 'there';
  const message = `Hi ${customerFirstName},\n\nPlease e-sign the application for your business insurance policy through Foxquilt Insurance Services.\n[button: Click to E-Sign Application]\n\nCoverage will be bound and your policy will be issued once both the application and the payment has been received. Both items must be received within 14 days so please action this promptly. Once this application is signed, if payment has not already been received, you will be prompted for payment.\n\nIf the required steps are not completed within 14 days of when the purchase process was started, any payment we've collected will be refunded and you will need to work with your agent to get a new business insurance quote.\n\nIf you have any questions, please contact your insurance agent.`;

  const [signOption, setSignOption] = useState('broker-sign');
  const [checkAgentAgreement, setCheckAgentAgreement] = useState(false);
  const [subject, setSubject] = useState(DEFAULT_EMAIL_SUBJECT);

  // sendTnCEmail API
  const [sendTnCESignatureSucceed, setSendTnCESignatureSucceed] = useState<
    boolean | undefined
  >(undefined);
  const [redirectToEsign, setRedirectToEsign] = useState(false);

  const [
    sendTnCeSignatureMutation,
    { loading: isSendTnCESignatureLoading, error: sendTnCESignatureError }
  ] = useSendTnCeSignatureMutation();

  const [cookies] = useCookies(['hubspotutk']);
  const hubspotTracker = cookies.hubspotutk;
  const [questionStartTime, setQuestionStartTime] = useState<Date | null>(null);

  useEffect(() => {
    logUserActivity({
      questionId: SIGN_OPTION_RADIO,
      questionAnswer: signOption,
      hubspotTracker
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signOption]);

  useEffect(() => {
    logUserActivity({
      questionId: AGENT_AGREEMENT_CHECKBOX,
      questionAnswer: checkAgentAgreement,
      hubspotTracker
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAgentAgreement]);

  const resetSubjectInput = () => {
    setSubject(DEFAULT_EMAIL_SUBJECT);
    logUserActivity({
      questionId: TNC_EMAIL_RESET_SUBJECT_INPUT,
      questionAnswer: DEFAULT_EMAIL_SUBJECT,
      hubspotTracker
    });
  };

  const sendForESignatureOnClick = async () => {
    if (quoteId) {
      const result = await sendTnCeSignatureMutation({
        variables: {
          quoteId,
          subject,
          message
        }
      });
      setSendTnCESignatureSucceed(result.data?.sendTnCESignature);
      logUserActivity({
        questionId: TNC_SEND_ESIGN_BUTTON,
        questionAnswer: 'Send for e-Signature button clicked',
        hubspotTracker
      });
    }
  };

  const redirectToESignOnClick = () => {
    logUserActivity({
      questionId: REDIRECT_TO_ESIGN_BUTTON,
      questionAnswer: 'Redirect to e-sign button clicked',
      hubspotTracker
    });
    setRedirectToEsign(true);
  };

  const returnToFoxdenOnClick = () => {
    logUserActivity({
      questionId: RETURN_TO_FOXDEN_BUTTON,
      questionAnswer: 'Return to Foxden button clicked',
      hubspotTracker
    });
    window.location.href = REACT_APP_ADMIN_FRONTEND_URL;
  };

  const BrokerAgreement = (
    <div>
      <p className="term-description sm:text-base text-xs">
        <b>How It Works: </b>Foxquilt uses Docusign to provide a safe and secure
        method for electronically signing the required documents. After checking
        off the agreement below and submitting, you will be directed to Docusign
        to electronically sign on behalf of your client.
      </p>
      <hr className="border-solid border-gray-500 mt-4 mb-4" />
      <h2 className="term-title sm:text-2xl text-xl">
        Agent/Broker E-Signature Agreement
      </h2>
      <p className="term-description sm:text-base text-xs mt-2">
        My customer agrees that I will electronically sign their documents on
        their behalf.
      </p>
      <div
        className="term-check text-base"
        onClick={() => {
          setCheckAgentAgreement(!checkAgentAgreement);
        }}
      >
        <input
          data-testid="agent-agreement-checkbox"
          type="checkbox"
          checked={checkAgentAgreement}
        />
        <label>The customer understands and agrees.</label>
      </div>
    </div>
  );

  const CustomerInfo = (
    <Fragment>
      {sendTnCESignatureSucceed ? (
        <div className="term-container mb-0">
          <h2 className="term-title sm:text-2xl text-xl text-secondary">
            Documents Emailed to client!
          </h2>
          <p className="term-description sm:text-base text-xs">
            If the client has not received the documents, we recommend that they
            check their spam folder.
          </p>
          <p className="term-description sm:text-base text-xs">
            We will notify you by email when your client completes the
            e-signature.
          </p>
          <p className="term-description sm:text-base text-xs">
            You can continue to purchase and select your client&apos; payment
            preferences while waiting for their e-signature.
          </p>
        </div>
      ) : (
        <div>
          <p className="term-description sm:text-base text-xs">
            <b>How It Works: </b>Foxquilt uses Docusign to provide a safe and
            secure method for your client to electronically sign their
            documents. At no extra cost, your client will receive a copy of
            their documents requiring signatures that they can sign online and
            submit back to Foxquilt. You will be notified by email as soon as
            your client has signed their documents.
          </p>
          <hr className="border-solid border-gray-500 mt-4 mb-4" />
          <div className="customer-info flex flex-col space-y-3 mt-4 sm:text-base text-sm">
            <h2 className="sm:text-2xl text-xl text-gray-500">
              <b>Customer&apos;s Info</b>
            </h2>
            <hr className="border-solid border-gray-500 mt-2 mb-2" />
            <span>
              <b className="mr-2">Customer&apos;s Name:</b>
              {
                getApplicationInfoDataResult?.getApplicationInfoByInvoiceId
                  ?.customerName
              }
            </span>
            <span>
              <b className="mr-2">Customer&apos;s Email Address:</b>
              {
                getApplicationInfoDataResult?.getApplicationInfoByInvoiceId
                  ?.customerEmail
              }
            </span>
          </div>
          <div className="agent-info mt-6 flex flex-col space-y-3 sm:text-base text-sm">
            <h2 className="sm:text-2xl text-xl text-gray-500">
              <b>Agent/Broker Info</b>
            </h2>
            <hr className="border-solid border-gray-500 mt-2 mb-2" />
            <div className="grid grid-cols-3 gap-4">
              <b className="col-span-1 mt-4 mr-2">Agent/Broker Name:</b>
              <input
                data-testid="broker-name"
                className="col-span-2 border-solid border-gray-500 border-lg"
                value={
                  getApplicationInfoDataResult?.getApplicationInfoByInvoiceId
                    ?.brokerName || ''
                }
                type="text"
                readOnly={true}
              />
              <b className="col-span-1 mt-4 mr-2">
                Agent/Broker Email Address:
              </b>
              <input
                data-testid="broker-email"
                className="col-span-2 border-solid border-gray-500 border-lg"
                value={
                  getApplicationInfoDataResult?.getApplicationInfoByInvoiceId
                    ?.brokerEmail || ''
                }
                type="text"
                readOnly={true}
              />
              <b className="col-span-1 mt-4 mr-2">Subject:</b>
              <input
                data-testid="subject"
                className="col-span-2 border-solid border-gray-500 border-lg"
                value={subject}
                type="text"
                onChange={(e) => setSubject(e.target.value)}
                onFocus={() => {
                  setQuestionStartTime(new Date());
                }}
                onBlur={() => {
                  logUserActivity({
                    questionId: TNC_EMAIL_EDIT_SUBJECT_INPUT,
                    questionAnswer: subject,
                    hubspotTracker,
                    questionStartTime
                  });
                }}
              />
              <div className="col-span-1 sm:block hidden" />
              <p className="col-span-3 sm:col-span-2 text-faded">
                Subject will also be the e-signature envelope name
              </p>
              {subject === DEFAULT_EMAIL_SUBJECT && (
                <div className="col-span-1 sm:block hidden" />
              )}
              {subject === '' ? (
                <>
                  <div className="col-span-1 sm:block hidden" />
                  <p className="col-span-3 sm:col-span-1 text-alert">
                    Subject field cannot be blank.
                  </p>
                </>
              ) : (
                <div className="col-span-1 sm:block hidden" />
              )}
              {subject !== DEFAULT_EMAIL_SUBJECT ? (
                <button
                  data-testid="reset-subject"
                  className={`col-span-3 sm:col-span-${
                    subject === '' ? '1' : '2'
                  } text-left text-secondary underline`}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={resetSubjectInput}
                >
                  {'Reset to default text.'}
                </button>
              ) : (
                <div className="col-span-1 sm:block hidden" />
              )}
              <div className="col-span-1 sm:hidden block" />
              <b className="col-span-1 mt-4 mr-2">Message to Client:</b>
              <textarea
                data-testid="message"
                className="col-span-2 border-solid border-gray-500 border-lg h-60"
                value={message}
                readOnly={true}
              />
              <div className="col-span-1 sm:block hidden" />
              <div className="sm:col-span-1 col-span-2">
                <button
                  className="send-esign bg-secondary text-left px-6 py-4 rounded-md text-white flex flex-row"
                  disabled={subject === ''}
                  onClick={sendForESignatureOnClick}
                >
                  {isSendTnCESignatureLoading ? (
                    <>
                      <Spinner className="mx-auto animate-spin h-6 w-6 text-white mr-2" />
                      Sending...
                    </>
                  ) : (
                    <>
                      <SendSharp className="mr-2" />
                      Send for e-Signature
                    </>
                  )}
                </button>
                {sendTnCESignatureSucceed === false ? (
                  <span className="text-alert">Your Quote has expired.</span>
                ) : undefined}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );

  const isLoading = getApplicationInfoLoading || !getApplicationInfoDataResult;

  if (redirectToEsign) {
    return (
      <Redirect
        to={{
          pathname: `/terms/${quoteId}`
        }}
      />
    );
  }

  if (sendTnCESignatureError) {
    throw Error('Fail to send e-signature, please try again.');
  }

  return (
    <div className="customer-agreement-container mx-auto text-center">
      <h1 className="text-2xl font-bold">
        Thank you for submitting your payment!
      </h1>
      <p className="text-sm my-2 font-semibold">
        To complete the purchase, the application must be signed within 14 days.
      </p>
      <div className="policy-warning">
        <Warning
          style={{ color: '#FFD64E' }}
          fontSize="large"
          className="mr-2"
        />
        Coverage is <b>NOT</b> bound, and a policy will <b>NOT</b> be issued
        until both the application is signed <b>AND</b> payment received.
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="term-container text-left">
          <p>
            We&apos;ve already emailed the application to be signed as
            previously elected. However, because coverage is <b>NOT</b> bound,
            and policy is <b>NOT</b> issued until the application is signed,
            we&apos;re giving another opportunity with two different ways to get
            the application signed:
          </p>
          <div
            className="term-check text-base"
            onClick={() => {
              setSignOption('broker-sign');
            }}
          >
            <input
              type="radio"
              data-testid="broker-sign"
              name="sign_option"
              checked={signOption === 'broker-sign'}
              defaultChecked={true}
            />
            <label>
              You can instantly sign the application on your client&apos;s
              behalf.
            </label>
          </div>
          <div
            className="term-check text-base"
            onClick={() => {
              setSignOption('client-sign');
            }}
          >
            <input
              type="radio"
              data-testid="client-sign"
              name="sign_option"
              checked={signOption === 'client-sign'}
            />
            <label>
              Foxquilt will send the application to your client for their
              e-signature.
            </label>
          </div>
          {isLoading ? (
            <Spinner className="mx-auto my-2 animate-spin h-8 w-8 text-gray-400" />
          ) : signOption === 'broker-sign' ? (
            BrokerAgreement
          ) : (
            CustomerInfo
          )}
        </div>
        <div className="mb-12 inline sm:block text-center">
          {signOption === 'broker-sign' ? (
            <button
              disabled={!checkAgentAgreement}
              className="proceed-esign text-left px-6 py-4 rounded-md text-white uppercase font-bold mr-2 text-center"
              onClick={redirectToESignOnClick}
            >
              Proceed to e-Sign
            </button>
          ) : null}
          <button
            className="return-foxden px-6 py-4 rounded-md text-white uppercase font-bold mr-2 text-center"
            onClick={returnToFoxdenOnClick}
          >
            Return to Foxden
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomerAgreement;
